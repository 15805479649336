import { React } from 'react';
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { ReactComponent as SNSBranchIcon } from '../../../assets/images/sns_brunch.svg';
import { ReactComponent as SNSBlog } from '../../../assets/images/sns_blog.svg';
import { ReactComponent as SNSKakao } from '../../../assets/images/sns_kakao.svg';
import { ReactComponent as SNSFaceBook } from '../../../assets/images/sns_facebook.svg';
import { ReactComponent as SNSInsta } from '../../../assets/images/sns_insta.svg';
import { ReactComponent as SNSX } from '../../../assets/images/sns_x.svg';
import { ReactComponent as SNSYoutube } from '../../../assets/images/sns_youtube.svg';
import { ReactComponent as SNSTiktok } from '../../../assets/images/sns_tiktok.svg';

const SubTitle = styled(Typography)(() => ({
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '21px',
  marginLeft: '8px',
  whiteSpace: 'nowrap',
}));
const SNSBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'flex-start',
  height: '56px',
  padding: '0px 0px 8px',
  marginBottom: '20px',
};
const Terms = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: 'none',
  color: 'black',
  padding: '0px',
}));
const Term = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}));
const SNSBox = ({ sideOn }) => {
  const sideStyle = {
    flex: 1,
    '&:hover': {
      backgroundColor: sideOn ? '#F5F7FA' : 'white',
    },
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '0px 0px',
      }}
    >
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://pf.kakao.com/_xkzxbFd', '_blank');
            }}
          >
            <SNSKakao />
            <SubTitle>카카오톡</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open(
                'https://www.youtube.com/@wadiz_makercenter',
                '_blank',
              );
            }}
          >
            <SNSYoutube />
            <SubTitle>유튜브</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open(
                'https://www.instagram.com/wadiz_makercenter',
                '_blank',
              );
            }}
          >
            <SNSInsta />
            <SubTitle>인스타그램</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            onClick={() => {
              window.open('https://blog.wadiz.kr/', '_blank');
            }}
            sx={sideStyle}
          >
            <SNSBlog />
            <SubTitle sx={{ margin: '0 18px 0 8px' }}>블로그</SubTitle>
          </Term>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://brunch.co.kr/@wadiz', '_blank');
            }}
          >
            <SNSBranchIcon />
            <SubTitle>브런치</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://twitter.com/Wadiz_funding', '_blank');
            }}
          >
            <SNSX />
            <SubTitle sx={{ margin: '0 18px 0 8px' }}>X</SubTitle>
          </Term>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://www.facebook.com/wadiz.funding', '_blank');
            }}
          >
            <SNSFaceBook
              style={{
                width: '20px',
                height: '20px',
              }}
            />
            <SubTitle>페이스북</SubTitle>
          </Term>
        </Terms>
      </Box>
      <Box sx={{ SNSBoxStyle }}>
        <Terms>
          <Term
            sx={sideStyle}
            onClick={() => {
              window.open('https://www.tiktok.com/@wadiz_official', '_blank');
            }}
          >
            <SNSTiktok
              style={{
                width: '20px',
                height: '20px',
              }}
            />
            <SubTitle>틱톡</SubTitle>
          </Term>
        </Terms>
      </Box>
    </Box>
  );
};
SNSBox.propTypes = {
  sideOn: PropTypes.bool,
};

SNSBox.defaultProps = {
  sideOn: false,
};

export default SNSBox;
